import { Component, OnDestroy, ChangeDetectorRef, AfterViewInit, OnInit, inject, signal, DestroyRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fadeAnimation, sliderAnimation } from '@shared/animations/animation';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { EventNotificationService } from '@app/services/event-notification.service';
import { StaffNotificationService } from '@app/services/staff-notification.service';
import { FooterComponent } from '../footer/footer.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'olla-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [fadeAnimation, sliderAnimation],
  standalone: true,
  imports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    RouterOutlet,
    MatSidenavModule
  ]
})
export class MainLayoutComponent implements OnInit, OnDestroy , AfterViewInit{

  readonly mode = signal<MatDrawerMode>('side');
  showSidenav = true;

  private readonly staffNotificationService = inject(StaffNotificationService);
  private readonly eventNotificationService = inject(EventNotificationService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly breakpointObserver = inject(BreakpointObserver);

  ngOnInit(): void {
    this.eventNotificationService.startConnection();
    this.eventNotificationService.startListener();

    this.staffNotificationService.startConnection();
    this.staffNotificationService.startListener();

    this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(
      result => {
        if (result.matches) {
          this.mode.set('side');
          this.showSidenav = true;
        } else {
          this.mode.set('over');
          this.showSidenav = false;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.eventNotificationService.stopConnection();
    this.staffNotificationService.stopConnection();
  }

  displaySidenav(): void {
    this.showSidenav = true;
  }

  prepareRoute(outlet: RouterOutlet) {
    const animation = 'animation';
    return outlet?.activatedRouteData[animation];
  }
}
