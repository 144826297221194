import { Component } from '@angular/core';

@Component({
  selector: 'olla-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true
})
export class FooterComponent {

  get currentYear(): number {
    return new Date().getFullYear();
  }

}
