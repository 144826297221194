import { Routes } from '@angular/router';
import { authGuard } from '@app/guards/auth.guard';
import { noAuthGuard } from '@app/guards/no-auth.guard';
import { MainLayoutComponent } from '@app/layout';
import { AuthLayoutComponent } from '@modules/auth/auth-layout/auth-layout.component';

export const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [noAuthGuard],
    canActivateChild: [noAuthGuard],
    loadChildren: () => import('@modules/auth/auth.routes').then(r => r.AUTH_ROUTES)
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        canActivate: [authGuard],
        canMatch: [authGuard],
        loadChildren: () => import("@modules/home/home.routes").then(r => r.HOME_ROUTES)
      },
      {
        path: 'sales',
        canActivate: [authGuard],
        canLoad: [authGuard],
        loadChildren: () =>
          import('@modules/sales/sales.routes')
          .then(r => r.SALES_ROUTES)
      },
      {
        path: 'strategy',
        canActivate: [authGuard],
        canLoad: [authGuard],
        loadChildren: () =>
          import('@modules/strategy/strategy.routes')
          .then(r => r.STRATEGY_ROUTES)
      },
      {
        path: 'settings',
        canActivate: [authGuard],
        canLoad: [authGuard],
        loadChildren: () =>
          import('@modules/settings/settings.routes')
          .then(r => r.SETTINGS_ROUTES)
      },
      {
        path: 'profile',
        canActivate: [authGuard],
        canLoad: [authGuard],
        loadChildren: () =>
          import('@modules/profile/profile.routes')
          .then(r => r.PROFILE_ROUTES)
      },
      {
        path: 'notifications',
        canActivate: [authGuard],
        canLoad: [authGuard],
        loadChildren: () =>
          import('@modules/notifications/notifications.routes')
          .then(r => r.NOTIFICATIONS_ROUTES)
      },
      { path: '', redirectTo: '/home', pathMatch: 'full'  },
      { path: '**', redirectTo: '/home', pathMatch: 'full'  }
    ]
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full'  }
];
