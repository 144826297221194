import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
import { tokenGetter } from '@app/helpers/auth.helper';

@Injectable({
  providedIn: 'root'
})
export class StaffNotificationService {
  private hubConnection: signalR.HubConnection | undefined;
  private hubUrl = `${environment.api_url}staffnotifications/real-time`;
  private signalRMessage$ = new Subject<StaffMessage>();
  signalRMessage = this.signalRMessage$.asObservable();

  public startConnection = () => {
    this.hubConnection = this.getConnection();
    this.hubConnection
      .start()
      .then(() => console.log(`Connected successfully to staff notifications`))
      .catch(err => console.log(`Error while connecting to staff notifications: ${err}`));
  }

  public stopConnection = () => {
    this.hubConnection?.stop()
      .then(() => console.log(`Disonnected successfully from staff notifications`))
      .catch(err => console.log(`Error while disconnecting from staff notifications: ${err}`));
  }

  public startListener = () => {
    this.hubConnection?.on('receiveMessage', (eventMessage: StaffMessage) => {
      this.signalRMessage$.next(eventMessage);
    })
  }

  private getConnection() {
    const connection = new signalR.HubConnectionBuilder()
    .withUrl(this.hubUrl, {
    accessTokenFactory: () => {
      return tokenGetter() ?? ''
    }, withCredentials: false
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.None)
    .build();

    return connection;
  }
}

export interface StaffMessage {
  notificationId: string;
  subject: string;
  message: string;
  createdAt: string;
}
