import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { errorInterceptor } from '@app/interceptors/error.interceptor';
import { httpConfigInterceptor } from '@app/interceptors/http-config.interceptor';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { JwtModule } from '@auth0/angular-jwt';
import { tokenGetter } from '@app/helpers/auth.helper';
import { environment } from '@environments/environment';
import { routes } from './app.routes';
import { provideNativeDateAdapter } from '@angular/material/core';


const { allowedDomains, disallowedRoutes } = environment;

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([httpConfigInterceptor, errorInterceptor]), withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideNativeDateAdapter(),
    provideLoadingBarInterceptor(),
    provideLoadingBarRouter(),
    provideCharts(withDefaultRegisterables()),
    provideHotToastConfig(),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter,
          allowedDomains,
          disallowedRoutes,
          skipWhenExpired: true
        },
      })
    )
  ],
};
