import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorResponse } from '@app/models/error.model';
import { AccountService } from '@app/services/account.service';

export function errorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const accountService = inject(AccountService);
  return next(req).pipe(catchError((err) => formatErrors(err, accountService)));
}

const formatErrors = (error: HttpErrorResponse, acctService: AccountService): Observable<never> => {
  const errorResponse: ErrorResponse = {
    message: '',
  };
  switch (error.status) {
    case 400:
    case 404:
      errorResponse.message = error.error.title || error.error.message;
      break;
    case 401:
      errorResponse.message = 'You are not signed in';
      acctService.logout();
      break;
    case 403:
      errorResponse.message = 'You are not authorised';
      break;
    default:
      errorResponse.message = 'Something went wrong';
      break;
  }

  if (!acctService.isLoggedIn()) {
    acctService.logout();
    errorResponse.message = 'You are not signed in';
  }
  return throwError(() => errorResponse);
}
