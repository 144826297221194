import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { fadeAnimation } from '@shared/animations/animation';

@Component({
  selector: 'olla-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [fadeAnimation],
  standalone: true,
  imports: [
    RouterOutlet,
    LoadingBarModule
  ]
})
export class AppComponent {}
