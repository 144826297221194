import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AccountService } from '@app/services/account.service';

@Component({
  selector: 'olla-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    NgIf,
    NgClass,
    AsyncPipe,
    RouterLink,
    RouterLinkActive
  ]
})
export class SidebarComponent {

  private accountService = inject(AccountService);

  readonly activeDropdown = signal('');
  readonly user$ = this.accountService.user$;

  currentDropdown(link: string): boolean {
    return this.activeDropdown() === link;
  }

  changeDropdown(link: string): void {
    if (this.currentDropdown(link)) {
      this.activeDropdown.set('');
    } else {
      this.activeDropdown.set(link);
    }
  }
}
