import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'olla-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    RouterOutlet
  ]
})
export class AuthLayoutComponent {
  readonly image = 'assets/Logo-main.png';
}
