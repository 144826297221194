<header>
  <!-- <nav class="navbar shadow navbar-light bg-white"> -->
  <nav class="shadow bg-[#fff] relative flex flex-wrap items-center justify-between px-4 py-2">
    <div>
      <a class="navbar-brand">
        <img src="https://res.cloudinary.com/davidcloudobi/image/upload/v1613554225/yus5bdftov3wp7zdet5z.png" (click)="clickLogo()" alt="Logo" class="logo mr-2">
        <span class="company">Olla Systems Limited</span>
      </a>
    </div>
    <div>
      <span (clickOutside)="hideNotifications($event)" class="">
        <button type="button" mat-icon-button (click)="toggleNotifications($event)" [ngClass]="!showNotifications() ? '' : 'bg-white'">
          <mat-icon *ngIf="hasNotification()" [matBadge]="userNotificationCount()" matBadgeSize="small" matBadgeColor="accent" aria-hidden="false">notifications_none</mat-icon>
          <mat-icon *ngIf="!hasNotification()">notifications_none</mat-icon>
        </button>
        <div class="notification-card" [ngClass]="showNotifications() ? '' : 'hidden opacity-0'">
          <header class="py-4 px-4 sticky">
            <div class="flex justify-between items-center">
              <div class="notificationTitle">
                <h6>Notifications</h6>
              </div>
              <div (click)="navigateToNotifications($event)">
                <a class="text-base">View More</a>
              </div>
            </div>
          </header>
          <div class="py-2 px-2">
            <!-- Messages -->
            <div *ngFor="let notification of userNotifications()">
              <a class="cursor-pointer">
                <div class="flex py-1 px-2 items-start">
                  <div class="mr-3 bell-icon">
                    <span class="w-full h-full p-2 flex items-center justify-center">
                      <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6772 16.0199H1.32276C0.973065 16.0205 0.637418 15.8823 0.389444 15.6357C0.14147 15.3892 0.00141494 15.0543 0 14.7046V11.8748C8.51394e-05 11.6582 0.0538295 11.445 0.156431 11.2542C0.259033 11.0635 0.407296 10.9011 0.587973 10.7816C0.842405 10.6123 1.13675 10.4074 1.3894 10.1729L1.80347 9.78879V5.94879C1.80383 3.01961 4.2694 0.636719 7.30013 0.636719H8.69987C11.7306 0.636719 14.1962 3.01961 14.1962 5.94879V9.78879L14.6102 10.1729C14.8629 10.4074 15.1572 10.6123 15.4117 10.7816C15.5924 10.901 15.7407 11.0634 15.8434 11.2542C15.9461 11.445 15.9999 11.6582 16 11.8748V14.7046C15.9986 15.0543 15.8585 15.3892 15.6106 15.6357C15.3626 15.8823 15.0269 16.0205 14.6772 16.0199ZM1.78174 14.2381H14.2183V12.1254C13.9299 11.9298 13.656 11.7138 13.3987 11.4789L12.6995 10.83C12.6096 10.7467 12.5379 10.6457 12.4889 10.5334C12.4398 10.421 12.4145 10.2998 12.4144 10.1772V5.94879C12.4144 4.00206 10.7482 2.41846 8.69987 2.41846H7.30013C5.25185 2.41846 3.58557 4.00206 3.58557 5.94879V10.1772C3.58552 10.2998 3.56017 10.421 3.51112 10.5334C3.46207 10.6457 3.39036 10.7467 3.30049 10.83L2.60134 11.4789C2.34401 11.7138 2.0701 11.9298 1.78174 12.1254V14.2381Z" fill="#7A807E"/>
                        <path d="M8.00062 18.879C7.13775 18.878 6.3105 18.5348 5.70036 17.9247C5.09023 17.3146 4.74704 16.4873 4.74609 15.6245C4.74609 15.3882 4.83995 15.1616 5.00702 14.9945C5.17409 14.8274 5.40069 14.7336 5.63696 14.7336C5.87324 14.7336 6.09983 14.8274 6.2669 14.9945C6.43397 15.1616 6.52783 15.3882 6.52783 15.6245C6.52783 16.0151 6.683 16.3897 6.9592 16.6659C7.2354 16.9421 7.61001 17.0972 8.00062 17.0972C8.39122 17.0972 8.76583 16.9421 9.04203 16.6659C9.31823 16.3897 9.4734 16.0151 9.4734 15.6245C9.4734 15.3882 9.56726 15.1616 9.73433 14.9945C9.9014 14.8274 10.128 14.7336 10.3643 14.7336C10.6005 14.7336 10.8271 14.8274 10.9942 14.9945C11.1613 15.1616 11.2551 15.3882 11.2551 15.6245C11.2542 16.4873 10.911 17.3146 10.3009 17.9247C9.69073 18.5348 8.86348 18.878 8.00062 18.879Z" fill="#7A807E"/>
                      </svg>
                    </span>
                  </div>
                  <div class="grow">
                    <h6 class="mt-0 mb-1 font-bold text-base txt-black">{{notification.subject}}</h6>
                    <div class="text-lg txt-black-lite">
                      <p class="mb-1 mt-0">{{notification.message}}</p>
                      <div class="mt-0 mb-0 flex">
                        <div class="mr-1">
                          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="17" width="12" x="0px" y="0px" viewBox="0 0 300.988 300.988" xml:space="preserve">
                            <g>
                              <g>
                                <path d="M150.494,0.001C67.511,0.001,0,67.512,0,150.495s67.511,150.493,150.494,150.493s150.494-67.511,150.494-150.493
                                  S233.476,0.001,150.494,0.001z M150.494,285.987C75.782,285.987,15,225.206,15,150.495S75.782,15.001,150.494,15.001
                                  s135.494,60.782,135.494,135.493S225.205,285.987,150.494,285.987z"/>
                                <polygon points="142.994,142.995 83.148,142.995 83.148,157.995 157.994,157.995 157.994,43.883 142.994,43.883 		"/>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p>{{notification.createdAt | dateAgo}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </span>

      <button type="button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </nav>

  <mat-menu #menu="matMenu">
    <button type="button" (click)="navigateToProfile()" mat-menu-item>
      <div class="flex items-center">
        <img class="avatar" [src]="userImage$ | async" alt="">
        <span>Profile</span>
      </div>
    </button>
    <button type="button" (click)="logout()" mat-menu-item>
      <mat-icon>login</mat-icon>
      <span>Sign Out</span>
    </button>
  </mat-menu>
</header>
