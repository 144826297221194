import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, CanMatchFn } from '@angular/router';
import { AccountService } from '@app/services/account.service';

export const authGuard:
  | CanActivateFn
  | CanActivateChildFn
  | CanMatchFn = () => {
  const oauthService: AccountService = inject(AccountService);
  if (oauthService.isLoggedIn()) {
    return true;
  }
  oauthService.logout();
  return false;
};
