
<mat-sidenav-container>
  <mat-sidenav [mode]="mode()" [(opened)]="showSidenav">
    <olla-sidebar></olla-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <olla-navbar (logoClicked)="displaySidenav()"></olla-navbar>
    <main class="main flex flex-col" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
      <router-outlet #o="outlet"></router-outlet>
      <olla-footer class="main__footer"></olla-footer>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
