import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, computed, inject, OnInit, output, signal } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { ErrorResponse } from '@app/models/error.model';
import { AccountService } from '@app/services/account.service';
import { StaffNotificationService } from '@app/services/staff-notification.service';
import { StaffNotificationResponse } from '@modules/settings/models/staff.model';
import { StaffService } from '@modules/settings/services';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateAgoPipe } from '@shared/pipes';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'olla-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    RouterLink,
    NgClass,
    NgFor,
    NgIf,
    DateAgoPipe,
    AsyncPipe,
    NgClickOutsideDirective
  ]
})
export class NavbarComponent implements OnInit {
  readonly logoClicked = output<void>();

  private readonly accountService = inject(AccountService);
  private readonly router = inject(Router)
  private readonly staffService = inject(StaffService)
  private readonly toast = inject(HotToastService)
  private readonly staffNotification = inject(StaffNotificationService)

  public readonly userImage$ = this.accountService.user$.pipe(map(user => user.staffImageUrl));
  public readonly userNotifications = signal<StaffNotificationResponse[]>([]);
  public readonly userNotificationCount = computed(() => this.userNotifications().length);
  public readonly hasNotification = computed(() => this.userNotifications().length > 0);
  public readonly showNotifications = signal(false);

  ngOnInit(): void {
    this.getLatestNotifications();
    this.receiveNotification();
  }

  private getLatestNotifications() {
    this.staffService.getLatestNotifications()
    .pipe(untilDestroyed(this))
    .subscribe({
      next: (notifications) => this.userNotifications.set(notifications),
      error: (err: ErrorResponse) => this.toast.error(err.message)
    })
  }

  private receiveNotification() {
    this.staffNotification.signalRMessage.pipe(untilDestroyed(this))
    .subscribe(message => {
      const notification: StaffNotificationResponse = { ...message, read: false };
      this.userNotifications.update(notifications => [notification, ...notifications]);
    })
  }

  clickLogo(): void {
    this.logoClicked.emit();
  }

  navigateToProfile(): void {
    this.router.navigateByUrl('/profile');
  }

  logout(): void {
    this.accountService.logout();
  }

  toggleNotifications(e: Event) {
    e.stopPropagation();
    this.showNotifications.update(value => !value);
  }

  hideNotifications(e: Event) {
    e.stopPropagation();
    this.showNotifications.set(false);
  }

  navigateToNotifications(e: Event): void {
    this.hideNotifications(e)
    this.router.navigateByUrl('/notifications');
  }
}
