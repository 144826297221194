import { HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';

export function httpConfigInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  if (!req.headers.has('File')) {
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
  }

  req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
  return next(req);
}
